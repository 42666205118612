// import "core-js/stable"
// import "regenerator-runtime/runtime"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./app.scss"

/* 
  To include modules, import and include them anywhere in your JS. For example, 
  uncommenting the following two lines will force the jquery chunk to be 
  generated. Normally, only code that is actually used will be output as modules.
*/
// import $ from "jquery"
// console.log( $ );

console.info("Bootstrapping Mara Consulting 💻");

const body = document.querySelectorAll('body')[0];
const menuContainer = document.querySelectorAll('#nav-container')[0];
const menuToggle = document.querySelectorAll('#menu-toggle')[0];
const parallaxObjects = document.querySelectorAll('.parallax');

menuToggle.addEventListener('click', () => {
  body.classList.toggle('nav-open');
  menuToggle.classList.toggle('is-active');
});

gsap.registerPlugin(ScrollTrigger);

parallaxObjects.forEach((p) => {
  var image = p.querySelector('img');
  var picture = p.querySelector('picture');
  var containerHeight = p.offsetHeight;
  var imageHeight = image.offsetHeight;
  var parallaxDistance = (((imageHeight / containerHeight) * 100) - 100);
  
  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: p,
      start: "top bottom",
      end: "bottom top",
      scrub: true,
      markers: false
    }
  });
  tl.to(picture, {yPercent: parallaxDistance, ease: "none"});
});

// Only used in dev
if (module.hot) {
  module.hot.accept();
}
